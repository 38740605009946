import { onLoad } from "../base/onLoad";
onLoad(function () {
    var searchOpen = false;
    var toggles = document.querySelectorAll('.search-bar__toggle');
    var searchBar = document.querySelector('.search-bar');
    var updateItems = document.querySelectorAll('[data-search-open]');
    if (!searchBar) {
        return;
    }
    function onOpen() {
        if (!searchBar) {
            return;
        }
        var input = searchBar.querySelector('input[type="search"]');
        if (input) {
            setTimeout(function () {
                input.select();
            }, 100);
        }
    }
    function toggleSearch() {
        searchOpen = !searchOpen;
        var attrValue = String(searchOpen);
        if (searchBar) {
            searchBar.setAttribute('aria-expanded', attrValue);
        }
        updateItems.forEach(function (item) {
            item.setAttribute('data-search-open', attrValue);
        });
        if (searchOpen) {
            onOpen();
        }
    }
    toggles.forEach(function (toggle) {
        toggle.addEventListener('click', function () {
            toggleSearch();
        });
    });
});
