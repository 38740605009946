import { onLoad } from "../base/onLoad";
onLoad(function () {
    var accordions = document.querySelectorAll('.accordion');
    accordions.forEach(function (accordion) {
        var header = accordion.querySelector('.accordion__header');
        if (!header)
            return;
        var state_attribute = 'aria-expanded';
        accordion.setAttribute(state_attribute, 'false');
        header.addEventListener('click', function () {
            var state = accordion.getAttribute(state_attribute) === 'true';
            accordion.setAttribute(state_attribute, String(!state));
        });
    });
});
