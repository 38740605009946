"use strict";
window.addEventListener('load', function () {
    var elements = Array.from(document.querySelectorAll('.check-overflowing'));
    elements.forEach(function (element) {
        var isOverflowing = element.scrollHeight > element.clientHeight;
        if (isOverflowing) {
            element.classList.add('is-overflowing');
        }
        var toggles = element.querySelectorAll('.toggle-overflowing');
        toggles.forEach(function (toggle) {
            toggle.addEventListener('click', function () {
                element.classList.toggle('expanded');
            });
        });
    });
});
