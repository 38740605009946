import { onLoad } from "../base/onLoad";
onLoad(function () {
    $(function () {
        $(document).on('click', '.woof_container_inner h4', function (e) {
            var _a;
            e.preventDefault();
            (_a = $(this).find('a').get(0)) === null || _a === void 0 ? void 0 : _a.click();
        });
    });
});
