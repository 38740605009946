import { onLoad } from './onLoad';
import AOS from 'aos';
// Start AOS on load
onLoad(function () {
    window.initAnimations();
});
/**
 * Initialize AOS animations
 */
window.initAnimations = function () {
    AOS.init({
        duration: 1000,
        once: true,
        offset: -60
    });
};
/**
 * Refresh AOS animations
 *
 *  This function needs to be used when aos elements are added to the DOM
 */
window.refreshAnimations = function () {
    AOS.refresh();
};
