import { onLoad } from "../../base/onLoad";
function pageHasElement(element) {
    return !!document.querySelector(element);
}
function merkenSlider() {
    if (!pageHasElement('.splide_logo_slider')) {
        return;
    }
    import(/* webpackChunkName: "merken-slider" */ './logo-slider').then(function (module) {
        module.default();
    });
}
function productSlider() {
    if (!pageHasElement('.product-slider')) {
        return;
    }
    import(/* webpackChunkName: "product-slider" */ './product-slider').then(function (module) {
        module.default();
    });
}
onLoad(function () {
    merkenSlider();
    productSlider();
});
