"use strict";
window.addEventListener('load', function () {
    (function () {
        var dateInput = document.querySelector('[name="afrfq_field_17897"]');
        if (!dateInput)
            return;
        var date = new Date();
        var format = 'Y-m-d';
        var formattedDate = date.toISOString().slice(0, 10);
        dateInput.min = formattedDate;
    })();
});
$(function () {
    $('body').on('click', '[data-quantity-button]', function (e) {
        e.preventDefault();
        var button = $(this);
        var input = button.siblings('input');
        if (!input.length)
            return;
        var shouldAdd = button.attr('data-quantity-button') === 'plus';
        var value = input.val();
        var isValidNumber = !isNaN(value);
        if (!isValidNumber)
            return;
        var valueAsNumber = +value;
        var newValue = shouldAdd ? valueAsNumber + 1 : valueAsNumber - 1;
        var min = input.attr('min');
        var max = input.attr('max');
        var isValidMin = !min || newValue >= +min;
        var isValidMax = !max || newValue <= +max;
        if (isValidMin && isValidMax) {
            input.val(newValue);
            input.trigger('change');
        }
        // const current = parseInt(input.val() as string);
        // const min = parseInt(input.attr('min') as string);
        // const max = parseInt(input.attr('max') as string);
        // const step = parseInt(input.attr('step') as string);
        // const newValue = shouldAdd ? current + step : current - step;
        // if(newValue < min || newValue > max) return;
        // input.val(newValue);
    });
});
