export function onLoad(callback, options) {
    if (options === void 0) { options = {}; }
    var checkForElements = options.checkForElements;
    window.addEventListener('load', function () {
        if (checkForElements) {
            var elements = checkForElements.map(function (el) { return document.querySelector(el); });
            if (elements.some(function (el) { return el === null; })) {
                return;
            }
        }
        callback();
    }, false);
}
// attach to window so that it can be used in the front-end
window.onLoad = onLoad;
/**
 * This function is a shorthand for the window DOMContentLoaded event
 * @param callback
 */
export function domLoaded(callback) {
    document.addEventListener('DOMContentLoaded', function () {
        callback();
    }, false);
}
// attach to window so that it can be used in the front-end
window.domLoaded = domLoaded;
