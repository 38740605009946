import { onLoad } from "../base/onLoad";
import './search-bar';
import './sliders/index';
import './accordion';
import './filter-open';
import './is-overflowing';
import './fixes';
import './notification-bar';
onLoad(function () {
    import(/* webpackChunkName: "data-src" */ './data-src').then(function (module) {
        module.default();
    });
});
