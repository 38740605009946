"use strict";
var Cookie = /** @class */ (function () {
    function Cookie() {
    }
    Cookie.exists = function (name) {
        return this.get(name) !== null;
    };
    Cookie.get = function (name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ')
                c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0)
                return c.substring(nameEQ.length, c.length);
        }
        return null;
    };
    Cookie.set = function (name, value, seconds) {
        var date = new Date();
        date.setTime(date.getTime() + (seconds * 1000));
        var expires = "; expires=" + date.toUTCString();
        document.cookie = name + "=" + value + expires + "; path=/";
    };
    Cookie.delete = function (name) {
        this.set(name, "", -1);
    };
    return Cookie;
}());
var NotificationBar = /** @class */ (function () {
    function NotificationBar(element) {
        var _this = this;
        this.element = element;
        var closebutton = this.element.querySelector('.notification_bar__inner__close');
        if (closebutton) {
            closebutton.onclick = function () { return _this.dismiss(); };
        }
        if (this.shouldShow()) {
            setTimeout(function () { return _this.show(); }, 1000);
        }
        else {
            this.element.remove();
        }
    }
    NotificationBar.prototype.dismiss = function () {
        var _this = this;
        Cookie.set(NotificationBar.cookie_dismissed_name, 'true', NotificationBar.lifetimeinSeconds);
        Cookie.set(NotificationBar.cookie_dismissed_timestamp_name, new Date().getTime().toString(), NotificationBar.lifetimeinSeconds);
        var el = $(this.element);
        el.slideUp(300, function () {
            _this.element.remove();
        });
    };
    NotificationBar.prototype.show = function () {
        var el = $(this.element);
        el.slideDown(300);
    };
    NotificationBar.prototype.shouldShow = function () {
        var lastChangedTimestamp = this.element.querySelector('input[name="last_msg_edit"]');
        if (lastChangedTimestamp) {
            var lastChangedTimestampValue = parseInt(lastChangedTimestamp.value);
            var lastDismissedTimestampValue = parseInt(Cookie.get(NotificationBar.cookie_dismissed_timestamp_name) || '0');
            console.log(lastChangedTimestampValue, lastDismissedTimestampValue);
            if (lastChangedTimestampValue > lastDismissedTimestampValue) {
                Cookie.delete(NotificationBar.cookie_dismissed_name);
                Cookie.delete(NotificationBar.cookie_dismissed_timestamp_name);
                return true;
            }
        }
        return !Cookie.exists(NotificationBar.cookie_dismissed_name);
    };
    NotificationBar.init = function () {
        if (NotificationBar.instance) {
            return;
        }
        var elementSelector = '.notification_bar';
        var element = document.querySelector(elementSelector);
        if (element) {
            NotificationBar.instance = new NotificationBar(element);
        }
    };
    NotificationBar.cookie_dismissed_name = 'notification_bar_dismissed';
    NotificationBar.cookie_dismissed_timestamp_name = 'notification_bar_dismissed_timestamp';
    NotificationBar.lifetimeinSeconds = 60;
    return NotificationBar;
}());
window.addEventListener('load', function () {
    NotificationBar.init();
});
